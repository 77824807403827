
<template>
  <div
    v-if="jackpot"
    class="odometer-area card1 mx-4 rounded py-2"
    :class="`odometer-area-${theme}`"
  >
    <div class="header-area text-center mb-1 d-flex align-center justify-center">
      <!-- icon -->
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12a12 12 0 1 0 24 0 12 12 0 0 0-24 0z"
          fill="#FFF7C5"
        />
        <path
          d="M2.625 12a9.375 9.375 0 1 0 18.75 0 9.375 9.375 0 0 0-18.75 0z"
          fill="#F9E230"
        />
        <path
          d="M2.69 13.085c0 2.2.98 4.308 2.726 5.863C7.162 20.503 9.53 21.376 12 21.376c2.469 0 4.837-.873 6.583-2.428 1.746-1.555 2.727-3.664 2.727-5.863 0-2.198-.981-4.307-2.727-5.862C16.837 5.668 14.469 4.795 12 4.795c-2.47 0-4.838.873-6.584 2.428C3.67 8.778 2.69 10.887 2.69 13.085z"
          fill="#F6DB2D"
        />
        <path
          d="M2.88 14.17c0 1.91.96 3.743 2.671 5.095 1.71 1.35 4.03 2.11 6.449 2.11 2.419 0 4.738-.76 6.449-2.11 1.71-1.352 2.671-3.184 2.671-5.095 0-1.91-.96-3.743-2.671-5.094-1.71-1.352-4.03-2.11-6.45-2.11-2.418 0-4.738.758-6.448 2.11-1.71 1.35-2.671 3.183-2.671 5.094z"
          fill="#F5D228"
        />
        <path
          d="M3.207 15.256c0 1.623.926 3.18 2.575 4.328 1.65 1.148 3.886 1.793 6.218 1.793s4.569-.645 6.218-1.793 2.575-2.705 2.575-4.328c0-1.623-.926-3.18-2.575-4.328-1.65-1.147-3.886-1.792-6.218-1.792s-4.569.645-6.218 1.792c-1.649 1.148-2.575 2.705-2.575 4.328z"
          fill="#F2C824"
        />
        <path
          d="M3.691 16.34c0 1.335.876 2.616 2.434 3.56 1.558.945 3.672 1.475 5.875 1.475 2.204 0 4.317-.53 5.876-1.475 1.558-.944 2.433-2.224 2.433-3.56 0-1.335-.875-2.616-2.433-3.56-1.559-.944-3.672-1.474-5.876-1.474-2.203 0-4.317.53-5.875 1.474-1.558.944-2.434 2.225-2.434 3.56z"
          fill="#F0BF21"
        />
        <path
          d="M15.648 13.425h-2.794v-1.359h2.794a.846.846 0 0 0 0-1.69h-1.74l1.77-1.83a.845.845 0 0 0-1.215-1.174l-2.468 2.55-2.532-2.557A.844.844 0 1 0 8.26 8.554l1.804 1.822H8.37a.846.846 0 0 0 0 1.69h2.794v1.36H8.37a.846.846 0 0 0 0 1.69h2.794v2.19a.846.846 0 0 0 1.69 0v-2.19h2.794a.846.846 0 0 0 0-1.69z"
          fill="#fff"
        />
      </svg>

      <span class="title--text font-weight-bold ml-2">
        JACKPOT
      </span>
    </div>
    <div class="text-center">
      <div
        ref="myOdometer"
        class="odometer"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    odometer: null,
    currentNum: 0,
    timer: null,
  }),

  computed: {
    ...mapGetters(['theme']),

    jackpot() {
      return this.data.data.value
    },
  },

  mounted() {
    if (this.jackpot) {
      // 初始化
      this.initOdometer()

      // 更新
      this.timer = setInterval(() => {
        this.updateOdometer(123)
      }, 5000)
    }
  },

  beforeDestroy() {
    // 清除timer
    this.$log('清除 updateOdometer')
    clearInterval(this.timer)
  },

  methods: {
    initOdometer() {
      this.currentNum = this.jackpot - (this.jackpot * 0.1)
      this.odometer = new window.Odometer({
        el: this.$refs.myOdometer,
        value: 0,
        format: ',ddd',
        theme: 'minimal',
      })

      this.odometer.update(this.currentNum)
    },

    updateOdometer(plusNum) {
      this.$log('updateOdometer')
      if (this.currentNum > this.jackpot) {
        this.$log('reset')
        this.currentNum = this.jackpot - (this.jackpot * 0.1)
      }
      this.odometer.update(this.currentNum += plusNum)
    },
  },
}
</script>

<style lang="scss">
.odometer-area {
  .odometer-formatting-mark {
    font-size: 20px;
    font-weight: bold;
  }
  .odometer-digit {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    margin: 0 .5rem;
    z-index: 1;
    color: #fff;
  }
  .odometer-digit::after {
    content: '';
    position: absolute;
    z-index: -1;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: -5px;
    right: -5px;
    bottom: 0;
    border-radius: 4px;
  }
}

// theme light
.odometer-area-light {
  .odometer-digit {
    color: #2a2a2a;
  }
  .odometer-digit::after {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
